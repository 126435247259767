<template>
  <div class="free-broad" :class="{ mobile: isMobile }">
    <div class="free-broad__wrapper">
      <div class="free-broad__header" v-if="!isMobile">
        <div class="free-broad__header__title">
          <span>오늘의 무료 방송</span>
        </div>
        <div class="free-broad__header__title4">
          <span>공지사항</span>
        </div>
      </div>

      <div class="broadcast-container" v-if="!isMobile">
        <div class="free-broad-container">
          <div class="free-broad__header__title" v-if="isMobile">
            <span>오늘의 무료 방송</span>
          </div>
          <div
            class="free-broad-card"
            v-for="(freeBroad, index) in processedExpertData"
            :key="index"
          >
            <div class="row1">
              <div class="profile">
                <div class="profile-image-container" @click="navigateToExpert(freeBroad.expert_id)">
                  <div class="triangle-background"></div>
                  <div :class="['profile-image', { 'image-error': freeBroad.imageError }]">
                    <img
                      :src="freeBroad.profile_img3"
                      @error="handleImageError(freeBroad.expert_id)"
                      alt="Profile Image"
                    />
                  </div>
                  <div v-if="isBroadcastLive(freeBroad)" class="live-badge-custom">
                    <div class="live-custom">
                      <v-icon>mdi-circle</v-icon>
                      <span>LIVE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row2">
              <div class="expert-des-container">
                <div class="expert-name">
                  <span>{{ freeBroad.expert_nickname }}</span>
                </div>
                <div class="expert-home-btn" @click="navigateToExpert(freeBroad.expert_id)">
                  <img class="img" :alt="`expert_home_svg`" :src="homeSvg" />
                </div>
                <!-- <div class="expert-kakao-btn" @click="openKakaoTalk(freeBroad.kakao_talk)">
                  <img class="img" :alt="`expert_kakao_svg`" :src="kakaoSvg" />
                </div> -->
              </div>
              <div class="free-broad-title">
                <p>{{ freeBroad.title }}</p>
              </div>
              <div
                class="free-broad-date"
                :class="{
                  'free-broad-free-date': freeBroad.type === 1,
                  'free-broad-record-date': freeBroad.kind === 1,
                }"
              >
                <span>{{ formatDate(freeBroad.start_date, freeBroad.end_date) }}</span>
              </div>
              <div class="expert-kakao-kakao-talk-btn">
                <button @click="openKakaoTalk(freeBroad.kakao_talk)">무료 카톡방</button>
              </div>
              <div
                class="free-broad-btn"
                :class="{
                  'free-broad-free-broadcast': freeBroad.type === 1,
                  'free-broad-record-broadcast': freeBroad.kind === 1,
                }"
                @click="joinBroadcast(freeBroad, freeBroad.expert_id)"
              >
                <span class="bottom-btn">
                  {{ freeBroad.type === 1 ? "무료 방송보기" : "녹화 방송보기" }}
                </span>
              </div>
            </div>
          </div>
          <div class="free-broad__header__title4" v-if="isMobile">
            <span>공지사항</span>
          </div>
          <NoticeList class="best-notice-list" :limit="noticeLimit"></NoticeList>
        </div>
      </div>

      <div class="free-broad__header__title" v-if="isMobile">
        <span>오늘의 무료 방송</span>
      </div>
      <section class="swiper-carousel-free-broad-container" v-if="isMobile">
        <swiper :options="swiperCarouselOptions" class="swiper-carousel-free-broad">
          <swiper-slide
            v-for="(freeBroad, index) in processedExpertData"
            :key="index"
            class="free-broad-card"
          >
            <div class="row1">
              <div class="profile">
                <div class="profile-image-container" @click="navigateToExpert(freeBroad.expert_id)">
                  <div class="triangle-background"></div>
                  <div :class="['profile-image', { 'image-error': freeBroad.imageError }]">
                    <img
                      :src="freeBroad.profile_img3"
                      @error="handleImageError(freeBroad.expert_id)"
                      alt="Profile Image"
                    />
                  </div>
                  <div v-if="isBroadcastLive(freeBroad)" class="live-badge-custom">
                    <div class="live-custom">
                      <v-icon>mdi-circle</v-icon>
                      <span>LIVE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row2">
              <div class="expert-des-container">
                <div class="expert-name">
                  <span>{{ freeBroad.expert_nickname }}</span>
                </div>
                <div class="expert-home-btn" @click="navigateToExpert(freeBroad.expert_id)">
                  <img class="img" :alt="`expert_home_svg`" :src="homeSvg" />
                </div>
                <!-- <div class="expert-kakao-btn" @click="openKakaoTalk(freeBroad.kakao_talk)">
                  <img class="img" :alt="`expert_kakao_svg`" :src="kakaoSvg" />
                </div> -->
              </div>
              <div class="free-broad-title">
                <p>{{ freeBroad.title }}</p>
              </div>
              <div
                class="free-broad-date"
                :class="{
                  'free-broad-free-date': freeBroad.type === 1,
                  'free-broad-record-date': freeBroad.kind === 1,
                }"
              >
                <span>{{ formatDate(freeBroad.start_date, freeBroad.end_date) }}</span>
              </div>
              <div class="expert-kakao-kakao-talk-btn">
                <button @click="openKakaoTalk(freeBroad.kakao_talk)">무료 카톡방</button>
              </div>
              <div
                class="free-broad-btn"
                :class="{
                  'free-broad-free-broadcast': freeBroad.type === 1,
                  'free-broad-record-broadcast': freeBroad.kind === 1,
                }"
                @click="joinBroadcast(freeBroad, freeBroad.expert_id)"
              >
                <span class="bottom-btn">
                  {{ freeBroad.type === 1 ? "무료 방송보기" : "녹화 방송보기" }}
                </span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
      <div class="free-broad__header__title4" v-if="isMobile">
        <span>공지사항</span>
      </div>
      <NoticeList v-if="isMobile" class="best-notice-list" :limit="noticeLimit"></NoticeList>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import NoticeList from "@/views/notice/NoticeList.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "FreeBroad",
  components: {
    NoticeList,
    Swiper,
    SwiperSlide,
  },
  props: {
    expertData: {
      type: Array,
      default: () => [],
      required: true,
      validator: function (value) {
        if (!Array.isArray(value)) {
          console.error("expertData must be an array");
          return false;
        }
        return true;
      },
    },
  },
  data: () => ({
    noticeLimit: 8,
    homeSvg: require("@/assets/img/expert_home.svg"),
    kakaoSvg: require("@/assets/img/expert_kakao.svg"),
    swiperCarouselOptions: {
      slidesPerView: "auto",
      spaceBetween: 40,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    },
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
    processedExpertData() {
      const now = dayjs();
      const todayStart = now.startOf("day");
      const todayEnd = now.endOf("day");

      const isFree = this.expertData
        .map((expert) => {
          return {
            ...expert,
            imageError: !expert.profile_img3,
          };
        })
        .filter(
          (expert) =>
            expert.type === 1 &&
            expert.start_date &&
            dayjs(expert.start_date).isAfter(todayStart) &&
            dayjs(expert.start_date).isBefore(todayEnd)
        )
        .sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))
        .slice(0, 2);

      if (isFree.length < 2) {
        const needed = 2 - isFree.length;
        const isRecording = this.expertData
          .filter(
            (expert) =>
              expert.type === 1 && expert.kind === 1 && expert.used === 1 && expert.start_date
          )
          .sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))
          .slice(0, needed);

        return isFree.concat(isRecording);
      }

      return isFree;
    },
  },
  methods: {
    ...mapActions(["validateToken"]),
    formatDate(start, end) {
      const startTime = dayjs(start);
      const endTime = dayjs(end);
      return `${startTime.format("MM월 DD일 HH:mm")}~${endTime.format("HH:mm")}`;
    },
    isBroadcastLive(best) {
      const now = dayjs();
      const start = dayjs(best.start_date);
      const end = dayjs(best.end_date);
      return now.isAfter(start) && now.isBefore(end);
    },
    navigateToExpert(expert_id) {
      if (expert_id) {
        this.$router.push({ name: "LineupLayout", params: { expert_id: expert_id } });
      }
    },
    openKakaoTalk(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    async joinBroadcast(data, expert_id) {
      try {
        let userId = null;
        let reqData = {};

        if (data.type === 0) {
          const userToken = await this.validateToken();

          if (
            !userToken ||
            !userToken.data ||
            !userToken.data.user ||
            userToken.data.user.length === 0
          ) {
            throw new Error("Invalid token data");
          }
          userId = userToken.data.user[0].user_id;
          reqData = {
            user_id: userId,
            expert_id: expert_id,
            base_url: `${window.location.origin}/${
              this.$router.resolve({
                name: "LineupLayout",
              }).href
            }`,
            register_url: `${window.location.origin}/${
              this.$router.resolve({
                name: "RegisterView",
              }).href
            }`,
          };
        } else if (data.type === 1) {
          const userToken = await this.validateToken().catch(() => {
            reqData = { expert_id: expert_id };
          });

          if (
            userToken &&
            userToken.data &&
            userToken.data.user &&
            userToken.data.user.length > 0
          ) {
            userId = userToken.data.user[0].user_id;
            reqData = {
              user_id: userId,
              expert_id: expert_id,
              base_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "LineupLayout",
                }).href
              }`,
              register_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "RegisterView",
                }).href
              }`,
            };
          }
        }

        const joinUrl = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/content/join_broadcast`,
          reqData
        );

        if (joinUrl.data.url && joinUrl.status === 200) {
          window.open(
            joinUrl.data.url,
            "_blank",
            "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=100,width=1024,height=768"
          );
        } else {
          alert("방송중이 아닙니다.");
        }
      } catch (error) {
        console.error("Error in joinBroadcast:", error.message);
        if (error.response && error.response.status === 401) {
          const confirmation = confirm("로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?");
          if (confirmation) {
            this.$router.push("/login");
          }
        } else if (error.response && error.response.data.message === "Invalid broadcast data") {
          alert("방송중이 아닙니다.");
        } else if (error.response && error.response.data.message === "deny") {
          alert("전문가 상품 구매 후 이용 가능한 방송입니다.");
        } else {
          alert("방송중이 아닙니다.");
        }
      }
    },
    async joinVod(data, schedule_id) {
      try {
        let joinUrl = null;
        if (data.type === 0) {
          const userToken = await this.validateToken();

          if (
            !userToken ||
            !userToken.data ||
            !userToken.data.user ||
            userToken.data.user.length === 0
          ) {
            throw new Error("Invalid token data");
          }
          const userId = userToken.data.user[0].user_id;

          joinUrl = await this.$axios.post(`${process.env.VUE_APP_API_URL}/content/join_vod`, {
            schedule_id: schedule_id,
            user_id: userId,
          });
        } else if (data.type === 1) {
          joinUrl = await this.$axios.post(`${process.env.VUE_APP_API_URL}/content/join_vod`, {
            schedule_id: schedule_id,
          });
        }

        if (joinUrl.data.url && joinUrl.status === 200) {
          window.open(
            joinUrl.data.url,
            "_blank",
            "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=100,width=1024,height=768"
          );
        } else {
          alert("VOD를 이용할 수 없습니다.");
        }
      } catch (error) {
        console.error("Error in joinBroadcast:", error.message);
        if (error.response && error.response.status === 401) {
          const confirmation = confirm("로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?");
          if (confirmation) {
            this.$router.push("/login");
          }
        } else {
          alert("VOD를 이용할 수 없습니다.");
        }
      }
    },
    handleImageError(expertId) {
      const index = this.processedExpertData.findIndex((expert) => expert.expert_id === expertId);
      if (index !== -1) {
        this.$set(this.processedExpertData, index, {
          ...this.processedExpertData[index],
          imageError: true,
        });
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.free-broad {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 33.125rem;
  background: #fff;
}
.free-broad.mobile {
  height: auto;
  max-height: 100%;
}

.free-broad__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
}

.free-broad__header {
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr 1.75fr;
  gap: 1.875rem;
  /* justify-content: flex-start;
  align-items: flex-start; */
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 4rem;
  background: #fff;
}

.free-broad__header__title {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 700;
  color: #212121;
  white-space: nowrap;
}
.free-broad__header__title4 {
  display: flex;
  align-items: center;
  grid-column: 4;
  grid-row: 1;
  width: 100%;
  font-size: 1.75rem;
  font-weight: 700;
  color: #212121;
  white-space: nowrap;
}

.free-broad.mobile .free-broad__header__title {
  justify-content: center;
  text-align: center;
  width: 100%;
}
.free-broad.mobile .free-broad__header__title4 {
  justify-content: center;
  text-align: center;
}

.broadcast-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 25.625rem;
}
.free-broad.mobile .broadcast-container {
  height: auto;
  max-height: 100%;
  margin-bottom: 1rem;
}

.free-broad-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr 0.25fr 1.75fr;
  max-height: 25.625rem;
  gap: 1.875rem;
}
.free-broad.mobile .free-broad-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  gap: 1.25rem;
}

.free-broad-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 17.5rem;
  height: 25.625rem;
  border-radius: 0.625rem;
  border: 0.065rem solid #e0e0e0;
}
.free-broad.mobile .free-broad-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 26.8rem;
  height: 224px;
  max-height: 15.4385rem;
  border: none;
}

.profile-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 11.565rem;
  overflow: hidden;
  border-radius: 0.625rem 0.625rem 0 0;
  background: #bbc2d4;
}
.free-broad.mobile .profile-image-container {
  width: 100%;
  max-width: 15.25rem;
  height: 100%;
  max-height: 15.44rem;
  border-radius: 0.625rem 0 0 0.625rem;
}

.row1 {
  width: 100%;
  height: 100%;
  max-height: 11.565rem;
}
.free-broad.mobile .row1 {
  width: 100%;
  max-width: 18.25rem;
  height: 100%;
  max-height: 15.4385rem;
  cursor: pointer;
  border-radius: 0.625rem 0 0 0.625rem;
}

.row2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0 0;
}
.free-broad.mobile .row2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 18.25rem;
  height: 100%;
  max-height: 15.4385rem;
  background: #fff;
  padding: 0;
}

.triangle-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.triangle-background::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #b7bed0;
  clip-path: polygon(0 100%, 0 0, 100% 100%);
  z-index: 0;
}

.row1 .profile {
  width: 100%;
  height: 100%;
}

.profile-image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  z-index: 2;
}
.free-broad.mobile .profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

.live-badge-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  right: 3%;
  width: 4rem;
  height: 1.5rem;
  opacity: 0.9;
  border-radius: 3.125rem;
  background: #d32f2f;
}

.live-custom {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.live-badge-custom i {
  color: #fff;
  font-size: 0.5rem;
}

.live-badge-custom span {
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  color: #f5f5f5;
}

.expert-des-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 2.5rem;
  gap: 0.4rem;
  padding: 0 0.5rem 0 0.5rem;
  z-index: 1;
}
.free-broad.mobile .expert-des-container {
  gap: 0;
  width: auto;
  border-top: 0.065rem solid #e0e0e0;
  border-right: 0.065rem solid #e0e0e0;
  border-radius: 0 0.625rem 0 0;
}

.expert-name {
  display: flex;
  flex-grow: 1;
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
  color: #212121;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expert-home-btn,
.expert-kakao-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.free-broad.mobile .expert-home-btn {
  right: 0;
}

.free-broad-title {
  position: relative;
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  max-height: 3.97rem;
  display: -webkit-box;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.free-broad.mobile .free-broad-title {
  max-height: 5.25rem;
  border-right: 0.065rem solid #e0e0e0;
  width: auto;
}

.free-broad-title p {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.85rem;
  text-align: left;
  color: #000000;
  margin: 0;
}

.free-broad-date {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 2rem;
}

.free-broad-date span {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
}

.free-broad-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 3rem;
  border-radius: 0 0 0.625rem 0.625rem;
  cursor: pointer;
  z-index: 1;
}
.free-broad.mobile .free-broad-btn {
  border-radius: 0 0 0.625rem 0;
}

.bottom-btn {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
}

.free-broad-free-date {
  background: #e3f2fd;
  color: #0d47a1;
}

.free-broad-record-date {
  background: #52adb01a;
  color: #00acc1;
}

@keyframes blink {
  0%,
  50% {
    background-color: #0e42aa;
  }
  50.1%,
  100% {
    background-color: #4589ff;
  }
}
.free-broad-free-broadcast {
  color: #e3f2fd;
  animation: blink 1.5s linear infinite;
}

.free-broad-record-broadcast {
  background: #00acc1;
  color: #e3f2fd;
}

.expert-kakao-kakao-talk-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
  padding: 10px;
  background: #fae300;
  color: #371c1d;
  font-size: 1rem;
  font-weight: bold;
  line-height: 28px;
  z-index: 1;
}
.free-broad.mobile .expert-kakao-kakao-talk-btn {
  padding: 0;
}

.best-notice-list {
  display: flex;
  justify-self: start;
  grid-column: 4;
  grid-row: 1;
  width: 100%;
}
.best-notice-list >>> .notice-list-wrapper {
  width: 100%;
  margin-bottom: 0;
}
.best-notice-list >>> .notice-list-wrapper .notice-list-header {
  display: none;
  /* width: 100%;
  font-size: 1.75rem;
  margin-bottom: 1.5rem; */
}
.best-notice-list >>> .notice-list-wrapper .notice-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
  border-radius: 0.625rem;
}
.best-notice-list >>> .notice-list-wrapper .notice-list .notice-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 3.075rem;
  cursor: pointer;
}
.best-notice-list >>> .notice-list-wrapper .notice-list .notice-list-item .notice-list-item-number {
  display: none;
}
.best-notice-list >>> .notice-list-wrapper .notice-list .notice-list-item .notice-list-item-title {
  height: 25px;
  max-width: 400px;
}
.best-notice-list
  >>> .notice-list-wrapper
  .notice-list
  .notice-list-item
  .notice-list-item-title::before {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
  background: #000;
  border-radius: 50%;
}
.best-notice-list >>> .notice-list-wrapper .notice-list .notice-list-item .notice-list-item-date {
  display: none;
}
.best-notice-list >>> .notice-list-wrapper .notice-list-header-sub {
  display: none;
}
.best-notice-list >>> .notice-list-wrapper nav {
  display: none;
}

.profile-image.image-error {
  background-image: url("@/assets/img/futureon-logo-white-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-image.image-error img {
  display: none;
}

.swiper-carousel-free-broad-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.swiper-carousel-free-broad {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
